document.addEventListener('DOMContentLoaded', () => {
    const button = document.querySelector('.navbar-toggler.hamburger-menu');

    function transformNavToggler() {
        if (button) {
            button.addEventListener('click', function() {
                const logo = document.querySelector('.logo-mangono');
                const navTogglerIcon = document.querySelector('.navbar-toggler-icon');

                if (logo) {
                    const isExpanded = button.getAttribute('aria-expanded') === 'true';

                    if (isExpanded) {
                        logo.style.visibility = 'hidden';
                        navTogglerIcon.style.backgroundImage = "url('data:image/svg+xml,<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.53333 17.4104L0.589661 16.4667L8.05633 9.00006L0.589661 1.53339L1.53333 0.589722L8.99999 8.05639L16.4667 0.589722L17.4103 1.53339L9.94366 9.00006L17.4103 16.4667L16.4667 17.4104L8.99999 9.94372L1.53333 17.4104Z\" fill=\"%23001E2B\"/></svg>')";
                    } else {
                        logo.style.visibility = '';
                        navTogglerIcon.style.backgroundImage = '';
                    }
                }
            });
        }
    }

    transformNavToggler();
});